import React, { FunctionComponent, useEffect, useState } from "react";
import "./footer.scss";
import footerlogImg from "../../../assets/images/logo.png";
import { ReactComponent as PhoneIcon } from "../../../assets/images/Icons/phoneIcon.svg";
import { ReactComponent as InIcon } from "../../../assets/images/Icons/inIcon.svg";
import { ReactComponent as InsIcon } from "../../../assets/images/Icons/insIcon.svg";
import { ReactComponent as TeleIcon } from "../../../assets/images/Icons/teleIcon.svg";
import { ReactComponent as WhatsIcon } from "../../../assets/images/Icons/whatsIcon.svg";
import { Menu } from "../../Menu/Menu";

interface FooterProps {}

export const Footer: FunctionComponent<FooterProps> = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const onScroll = (e: any) => {
      if (window.scrollY > 30) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  // const handleClick = () => {
  //   const anchor = document.querySelector(`#contact`)
  //   anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  // }

  // const handleShowModal = () => {
  //   setIsShowModal(!isShowModal);
  // };

  const dataMenuFooter = [
    { menu: "خانه", anchorId: "app" },
    { menu: "خدمات ما", anchorId: "services" },
    { menu: " تکنولوژی ها", anchorId: "logoCompanyWrapper" },
    { menu: "تماس با ما", class: "contact", anchorId: "contact" },
  ];
  return (
    <footer className="footerWrapper">
      <div className="container">
        <div className="content">
          <div className="logoWrapper">
            <img src={footerlogImg} alt="" className="logo" />
          </div>
          <Menu className="hiddenMobile" data={dataMenuFooter} />
          <div className="socialWrapper">
            <div className="social">
              <InIcon />
              <InsIcon />
              <TeleIcon />
              <WhatsIcon />
            </div>
            <div className="phoneWrapper hiddenMobile">
              <label>۰۷۱ - ۳۶۳۱۰۵۱۷</label>
              <PhoneIcon />
            </div>
          </div>
          <p className="copyWrite">
            کلیه حقوق این سایت متعلق به شرکت فناوری اطلاعات رادهوش داده نگار است
          </p>
        </div>
      </div>
    </footer>
  );
};
