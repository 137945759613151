import React, { FunctionComponent, useEffect, useState } from "react";
import { Images } from "../Images";
import "./header.scss";
import logImg from "../../assets/images/logo.png";
import headerImg from "../../assets/images/headerImg.png";
import { LinkButton } from "../../components/LinkButton/LinkButton";
import { ReactComponent as ArrowLeft } from "../../assets/images/Icons/arrowLeft.svg";
import { ReactComponent as CircleSvg } from "../../assets/images/Icons/circleIcon.svg";
import { ReactComponent as QuartercircleSvg } from "../../assets/images/Icons/quartercircle.svg";
import { ReactComponent as PhoneSvg } from "../../assets/images/Icons/phone.svg";
import { ReactComponent as MenuRSvg } from "../../assets/images/Icons/menuR.svg";
import { ModalFullScreenMobile } from "../ModalFullScreenMobile/ModalFullScreenMobile";
import { Menu } from "../Menu/Menu";
import { Link } from "react-router-dom";

interface HeaderProps { }

export const Header: FunctionComponent<HeaderProps> = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const onScroll = (e: any) => {
      if (window.scrollY > 30) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const dataMenuDes = [
    { menu: "خانه", anchorId: "app" },
    { menu: "خدمات ما", anchorId: "services" },
    { menu: " تکنولوژی ها", anchorId: "logoCompanyWrapper" },
    { menu: "فرصت های شغلی", anchorId: "team" },
    { menu: "تماس با ما", class: "contact", anchorId: "contact" },
  ];
  const dataMenuR = [
    { menu: "خانه", anchorId: "app" },
    { menu: "خدمات ما", anchorId: "services" },
    { menu: " تکنولوژی ها", anchorId: "logoCompanyWrapper" },
    { menu: "فرصت های شغلی", anchorId: "team" },
  ];

  const handleShowModal = () => {
    setIsShowModal(!isShowModal);
  };

  const handleClick = () => {
    const anchor = document.querySelector(`#contact`)
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (<>
    <div className={`topHeader ${scrolling && "sticky"}`}>
      <div className="container">
        <div className="menuWrapper">
          <Menu className="hiddenMobile" data={dataMenuDes} />
          <Link
            onClick={handleClick}
            to={'/'}>
            <PhoneSvg className="hiddenDes" />
          </Link>

          <Link
            to={'/'} className="logo">
            <Images src={logImg} />
          </Link>
          <MenuRSvg className="hiddenDes" onClick={handleShowModal} />
        </div>
      </div>
    </div>
    <header id="header" className="headerWrapper">
      <div className="container">
        <div className="headerImgWrapper">
          <div className="row">
            <div className="col-lg-7">
              <div className="imgWrapper">
                <QuartercircleSvg className="quartercircleSvgPink" />
                <Images src={headerImg} />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="colRight">
                <CircleSvg className="circleSvg" />
                <QuartercircleSvg className="quartercircleSvgYellow" />
                <h5>شرکت فناوری اطلاعات</h5>
                <h1>رادهوش داده نگار</h1>
                <CircleSvg className="circleSvgPink" />
                <p>
                  {`مجموعه ای فعال در حوزه تولید نرم افزار، متشکل از بهترین متخصص ها، پیشرو در تکنولوژی های جدید.`}
                </p>

                <QuartercircleSvg className="quartercircleSvg" />
                <div className="btnWrapper">
                  <LinkButton
                    link="/"
                    className="assistBtn"
                    label="همکاری با ما"
                    svgIcon={<ArrowLeft />}
                    anchorId="team"
                  />
                  <LinkButton
                    link="/"
                    label="خدمات رادهوش"
                    svgIcon={<ArrowLeft />}
                    anchorId="services"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFullScreenMobile
        showModal={isShowModal}
        children={<Menu data={dataMenuR} handleCloseModal={handleShowModal} />}
      />
    </header>
  </>
  );
};
