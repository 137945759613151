import { Header } from "./components/common/Header";
import { HomePage } from "./pages/HpmePage/HomePage";
import { Footer } from "./components/common/Footer/Footer";
import { Route, Routes } from "react-router";

function App() {
  return (
    <div id="app" className="app">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
