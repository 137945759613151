import React, { FunctionComponent } from "react";
import "./sliderItem.scss";

interface SliderItemProps {
  src?: string;
  text?: string;
  title?: string;
  alt?: string;
}

export const SliderItem: FunctionComponent<SliderItemProps> = ({
  src,
  title,
  text,
  alt,
}) => {
  return (
    <div className="sliderItemWrapper">
      <div className="imgWrapper">
        <img className="imgSlider" src={src} alt={alt} />
      </div>
      <div className="sliderItem">
        <h3 className="title">{title}</h3>
        <p className="text" dangerouslySetInnerHTML={{ __html: `${text}` }} />
      </div>
    </div>
  );
};
