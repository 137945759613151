import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import "./menu.scss";
import { ReactComponent as CloseSvg } from "../../assets/images/Icons/close.svg";

interface MenuProps {
  className?: string;
  data?: any;
  handleCloseModal?: () => void;
}

export const Menu: FunctionComponent<MenuProps> = ({
  className,
  data,
  handleCloseModal,
}) => {
  const [isIndex, setIsIndex] = useState(0);

  const handleClick = (anchorId: string, index: number) => {
    const anchor = document.querySelector(`#${anchorId}`);
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    handleCloseModal && handleCloseModal();
    setIsIndex(index);
  }

  return (
    <div className={`rhMenuWrapper ${className}`}>
      {handleCloseModal && (
        <header className="header">
          <CloseSvg onClick={handleCloseModal} />
          <label>منو</label>
        </header>
      )}
      <nav className={`rhMenu`}>
        {data?.map((m: any, index: any) => (
          <NavLink
            key={index}
            to={`/${m.link ?? ""}`}
            // className={({ isActive }) =>
            //   [m.class ?? "", isActive ? `activeClassName` : undefined]
            //     .filter(Boolean)
            //     .join(" ")
            // }
            className={`${isIndex === index ? `activeClassName ${m.class}` : m.class}`}
            onClick={() => handleClick(m.anchorId, index)}
          >
            {m.menu}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};
