import React, { FunctionComponent, useState } from "react";
import "./tabs.scss";

interface TabsProps {
  data?: any;
  active: any;
}

export const Tabs: FunctionComponent<TabsProps> = ({ data, active }) => {
  const activeData = {
    id: `${active}`,
    text: data[active].text,
  };
  const [activeTab, setActiveTab] = useState(activeData);
  const [activIndex, setActivIndex] = useState(`${active}`);
  const handleClickTab = (t: any, index: any) => {
    setActiveTab({ ...activeTab, id: t.id, text: t.text });
    setActivIndex(index);
  };

  return (
    <div className="tabsWrapper">
      <ul className="tabsTitleWrapper">
        {data.map((t: any, index: any) => (
          <>
            <li
              className={`title ${`${activIndex}` === `${index}` ? "active" : ""
                }`}
              key={index}
            >
              <button onClick={() => handleClickTab(t, index)}>
                {t.title}
              </button>
            </li>
            {/* {`${index}` === `${activIndex}` && (
              <li
                className="tabContent"
                dangerouslySetInnerHTML={{ __html: `${t.text}` }}
              />
            )} */}
          </>
        ))}
      </ul>
      {`${activIndex}` === activeTab.id && (
        <div
          className={`tabContent tabContent${activIndex}`}
          dangerouslySetInnerHTML={{ __html: `${activeTab.text}` }}
        />
      )}
    </div>
  );
};
