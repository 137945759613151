import React, { FunctionComponent } from "react";
// import { Link } from "react-router-dom";
import "./productCard.scss";
import { ReactComponent as CircleSvg } from "../../assets/images/Icons/circleIcon.svg";
import { ReactComponent as QuartercircleSvg } from "../../assets/images/Icons/quartercircle.svg";

interface ProductCardProps {
  ProductCard?: string;
  alt?: string;
  src?: string;
  text?: string;
  title?: string;
  className?: string;
  vertical?: boolean;
  label?: string;
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({
  src,
  alt,
  title,
  text,
  vertical,
  label,
}) => {
  return (
    <div className={`productCard ${vertical ? "productWrapper" : ""}`}>
      <div className="textWrapper">
        <h3 className="title">{title}</h3>
        <p className="text" dangerouslySetInnerHTML={{ __html: `${text}` }} />
        {/* {!!label && (
          <Link to="/" className="linkBtn">
            {label}
          </Link>
        )} */}
        {!!label && (
          <a href="https://jobinja.ir/companies/radhoosh/jobs" className="linkBtn" target="_blank" rel="noreferrer">
            {label}
          </a>
        )}
        <QuartercircleSvg className="quartercircleSvgPink" />
        <CircleSvg className="circleSvg" />
      </div>
      {src && <img className="imgProduct" src={src} alt={alt} />}
    </div>
  );
};
