
import React, { FunctionComponent, useState } from "react";
import "./Images.scss";


interface ImagesProps {
    src?: string,
    srcMobile?: string,
    srcTablet?: string,
    alt?: string,
    className?: string,
    itemprop?: string,
    loading?: "eager" | "lazy",
    onClick?: () => void
}

export const Images: FunctionComponent<ImagesProps> = ({ onClick, loading, itemprop, src, srcMobile, srcTablet, alt, className }) => {
    const [failed, setFailed] = useState(false);

    const onError = () => {
        setFailed(true)
    }

    return (
        <picture className={`${className}`}>
            {srcMobile && <source media="(max-width: 767px)" srcSet={srcMobile} />}
            {srcTablet && <source media="(max-width: 992px) and (min-width: 768px)" srcSet={srcTablet} />}
            <img loading={loading} onClick={onClick} itemProp={itemprop} className={`${failed ? 'errorImg' : ''}`} src={!failed && src !== "undefined" ? src : 'defaultImg.png'} alt={alt} onError={onError} />
        </picture>
    );
};

