import { FunctionComponent } from "react";
import { Title } from "../../components/Title/Title";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { Tabs } from "../../components/Tabs/Tabs";
import {
  dataProduct,
  dataBlockchain,
  logoCompany,
  dataTabs,
  seeJobData,
  sliderItemData,
} from "../Data";
import "./homePage.scss";
import contactImg from "../../assets/images/contactImg.png";
import { SliderItem } from "../../components/SliderItem/SliderItem";
import { ReactComponent as GlobeSvg } from "../../assets/images/Icons/globe.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

interface HomePageProps { }

export const HomePage: FunctionComponent<HomePageProps> = () => {
  return (
    <main className="homePage">
      <div id="services" className="servicesWrapper">
        <div className="container">
          <Title className="servicesTitle" title="خدمات رادهوش" />

          
          <ProductCard
            vertical
            title={dataBlockchain.title}
            src={dataBlockchain.src}
            text={dataBlockchain.text}
          />
          <GlobeSvg className="globeSvg" />
          {dataProduct.map((p, index) => (
            <ProductCard
              key={index}
              src={p.src}
              title={p.title}
              text={p.text}
            />
          ))}
        </div>
      </div>
      <div id="logoCompanyWrapper" className="logoCompanyWrapper">
        <div className="container">
          <Title className="logoCompanyTitle" title="تکنولوژی ها" />
          <h6>ماباچه تکنولوژی هایی کارمی کنیم؟؟؟</h6>
          <div className="logoWrapper">
            {logoCompany.map((l, index) => (
              <img key={index} className="imgLogo" src={l.src} alt="logo" />
            ))}
          </div>
        </div>
      </div>

      <div className="slideShowWrapper">
        <div className="container">
          <Title className="organizationalTitle" title="فرهنگ سازمانی" />
          <Swiper
            spaceBetween={30}
            effect={window.innerWidth > 767 ? "fade" : "slide"}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            navigation={true}
            modules={[Autoplay, EffectFade, Navigation, Pagination]}
            className="organizationaSlider"
          >
            {sliderItemData &&
              sliderItemData.map((s: any, index) => (
                <SwiperSlide key={index}>
                  <SliderItem title={s.title} text={s.text} src={s.src} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>

      <div id="team" className="team">
        <div className="seeJobWrapper">
          <div className="container">
            <ProductCard
              title={seeJobData.title}
              src={seeJobData.src}
              text={seeJobData.text}
              label={seeJobData.label}
            />
          </div>
        </div>
      </div>

      <div id="contact" className="contactWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              <div>
                <img src={contactImg} alt="" />
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="addressWrapper">
                <Title className="contactTitle" title="ارتباط با ما" />
                <Tabs active={1} data={dataTabs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
