import imgone from "../assets/images/ProductImg/imgOne.png";
import imgTwo from "../assets/images/ProductImg/imgTwo.png";
import imgThree from "../assets/images/ProductImg/imgThree.png";
import imgFour from "../assets/images/ProductImg/imgFour.png";
import imgFive from "../assets/images/ProductImg/imgFive.png";
import logoOne from "../assets/images/Logo/logoOne.png";
import logoTwo from "../assets/images/Logo/logoTwo.png";
import logoThree from "../assets/images/Logo/logoThree.png";
import logoFour from "../assets/images/Logo/logoFour.png";
import logoFive from "../assets/images/Logo/logoFive.png";
import logoSix from "../assets/images/Logo/logoSix.png";
import logoSeven from "../assets/images/Logo/logoSeven.png";
import logoEight from "../assets/images/Logo/logoEight.png";
import logoNine from "../assets/images/Logo/logoNine.png";
import logoTen from "../assets/images/Logo/logoTen.png";
import logoEleven from "../assets/images/Logo/logoEleven.png";
import imgSliderOne from "../assets/images/sliderImg/imgSliderOne.png";
import imgSliderTwo from "../assets/images/sliderImg/imgSliderTwo.png";

export const dataProduct = [
  {
    title: "طراحی وبسایت",
    text: `در رادهوش، طراحی وب، به صورت حرفه ای دنبال می شود.<br>
    ما برای هر مشتری، به صورت اختصاصی از همان ابتدا، آنچه که کاملا منطبق با نیاز اوست را تحلیل و طراحی می کنیم. و حتی پس از تحویل کار، تا هر زمان که نیاز باشد به عنوان پشتیبان همراه اوییم.`,
    src: `${imgThree}`,
  },
  {
    title: "هوش مصنوعی",
    text: `با وجود اینکه هوش مصنوعی مدت ها است که در دنیا در حال استفاده است، اما چند سالی است که واقعا قدرت این تکنولوژی بصورت غیر قابل باوری ارتقاء یافته. بدون هوش مصنوعی واقعا دیگر هیچ کسب و کاری نمی تواند میان رقبایش حرفی برای گفتن داشته باشد. ما اینجا به شما کمک می کنیم کسب و کار خود را متحول کنید.`,
    src: `${imgone}`,
  },
  {
    title: "بازی سازی",
    text: `بی شک یکی از پیچیده ترین حوزه های تولید نرم افزار، بازی سازی است. به دلیل گستردگی تخصص های این حوزه ، ما عمده تمرکز خود را بر روی واقعیت مجازی، با استفاده از موتور بازی سازی Unreal Engine گذاشته ایم.`,
    src: `${imgTwo}`,
  },
];

export const dataBlockchain = {
  title: "بلاکچِین",
  text: `این روز ها دنیای وب در حال یک تحول اساسی است.
        Web3 آمده تا بسیاری از شیوه های قبلی مدیریت اطلاعات را دستخوش تغییر دهد
        فضای شفاف اطلاعاتی و مالی، بهبود در مالکیت خصوصی، غیر متمرکز سازی و ده ها مزیت دیگر در این تحول درحال تولد است.
        ما نیز به صورت جدی وارد این حوزه شده ایم.`,
  src: `${imgFour}`,
};
export const seeJobData = {
  title: "!جای شما اینجا خالیه",
  text: `اگه دنبال یه فرصت خوب شغلی میگردی به پروفایل ما در جابینجا سر بزن و رزومه خودت رو برامون ارسال کن.<br>
  منتظر دیدار صمیمانه شما هستیم 😌`,
  src: `${imgFive}`,
  label: "دیدن فرصت های شغلی",
};

export const sliderItemData = [
  {
    title: "!همه چیز منظمه",
    text: `<span>برای حرفه ای شدن باید به اصول حرفه ای ها پایبند بود.</span>
  ما سعی کرده ایم پروژه هایمان را با متودولوژی اسکرام پیش ببریم.
  برنامه ریزی و نظم یکی از بزرگترین مزیت های رادهوش است`,
    src: `${imgSliderOne}`,
  },
  {
    title: "فضای کاری حرفه ای",
    text: `<span>همیشه حرفه ای ها از ابزار های حرفه نیز استفاده می کنند</span>
    ما اینجا به محیط و ابزارهای کارمان اهمیت می دهیم.
    و تمام اینها نیز به کمک و مشورت همکاران پدید آمده`,
    src: `${imgSliderOne}`,
  },

  {
    title: "بکار گیری به روز ترین تکنولوژی ها",
    text: `<span>اینجا سخت افزار و نرم افزار، هر دو باید به روز باشد.
    <span>اگر می خواهیم کاری کنیم که در خورمان باشد باید هزینه اش را بدهیم
    تمام همکاران ما اینجا بخشی از وقتشان صرف تحقیق و توسعه می شود`,
    src: `${imgSliderOne}`,
  },
  {
    title: "سمت های شغلی تخصصی",
    text: `<span>ضرب المثل «یک ده آباد، به از صد شهر خراب» را که شنیده اید .اینجا هر کسی تخصصی روی سمت شغلی خودش فعال است.</span>
    ما چرخه ی کامل طراحی ، تولید ، انتشار، و نگهداری نرم افزار را داریم.`,
    src: `${imgSliderOne}`,
  },
  {
    title: "آموزش در کنار تخصص",
    text: `<span>با وجود اینکه ورود به رادهوش مستلزم داشتن تجربیات قبلی است
    ولی به صورت کلی تخصص بدون آموزش بدست نمی آید.
    اینجا همیشه چند نفر در حال تحقیق و یا آموزش  پیدا می کنید.`,
    src: `${imgSliderOne}`,
  },
  {
    title: "تفریح و سرگرمی",
    text: `فضای کاری قرار نیست این قدر رسمی و بی روح باشد
   <span>سرگرمی، و فضای دور همی به افزایش روحیه و سلامت کمک می کند
    ما حتی نهار را هر روز دور همی، مهمان شرکت هستیم`,
    src: `${imgSliderTwo}`,
  },
];

export const logoCompany = [
  {
    src: `${logoOne}`,
  },
  {
    src: `${logoTwo}`,
  },
  {
    src: `${logoThree}`,
  },
  {
    src: `${logoFour}`,
  },
  {
    src: `${logoFive}`,
  },
  {
    src: `${logoSix}`,
  },
  {
    src: `${logoSeven}`,
  },
  {
    src: `${logoEight}`,
  },
  {
    src: `${logoNine}`,
  },
  {
    src: `${logoTen}`,
  },
  {
    src: `${logoEleven}`,
  },
];
export const dataTabs = [
  {
    id: "0",
    title: "شمارمون چنده؟",
    text: `<p class="phone">۰۷۱- <label>تلفن :</label> ۳۶۳۱۰۵۱۷ <br> <label>همراه:</label>۰۹۱۷۳۲۸۷۴۷۰</p>`,
  },
  {
    id: "1",
    title: "کجاییم؟",
    text: `<p class="place">شیراز، بلوار قدوسی غربی، روبروی درمانگاه میلاد، پلاک ۴۵۴، طبقه دوم.</p> <a target="_blanck" class="address" href="https://www.google.com/maps/place/7HXJJFRF%2B3Q7/@29.640891005214534,52.47301522434907,20.79z">مشاهده آدرس روی نقشه</a>`,
  },
];
