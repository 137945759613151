import React, { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";
import "./linkButton.scss";

interface LinkButtonProps {
  label?: string;
  svgIcon?: ReactElement;
  className?: string;
  link: string;
  anchorId?: string;
}

export const LinkButton: FunctionComponent<LinkButtonProps> = ({
  label,
  svgIcon,
  className,
  link,
  anchorId
}) => {

  const handleClick = () => {
    const anchor = document.querySelector(`#${anchorId}`)
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Link
      onClick={handleClick}
      className={`rhLinkbutton ${className}`} to={link}>
      {svgIcon}
      {label}
    </Link>
  );
};
