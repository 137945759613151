import React, { FunctionComponent, ReactNode, useEffect } from "react";
import "./modalFullScreenMobile.scss";

interface ModalFullScreenMobileProps {
  children?: ReactNode;
  showModal?: boolean;
  overlay?: boolean;
  clickChild?(): void;
  handleCloseModal?(): void;
}

export const ModalFullScreenMobile: FunctionComponent<
  ModalFullScreenMobileProps
> = ({
  clickChild,
  children,
  showModal,
  overlay,
  handleCloseModal,
}: ModalFullScreenMobileProps) => {
  useEffect(() => {
    let element = document.querySelector("body");
    showModal
      ? element?.classList.add("rhOverflow")
      : element?.classList.remove("rhOverflow");
  }, [showModal]);

  const handleClickChild = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    clickChild && clickChild();
  };

  const handleClickParentCloseModal = () => {
    let element = document.querySelector("body");
    element?.classList.remove("rhOverflow");
    handleCloseModal && handleCloseModal();
  };

  return (
    <div
      className={`modalFullScreenMobile ${showModal ? "showModal" : ""} ${
        overlay ? "overlay" : ""
      }`}
      onClick={handleClickParentCloseModal}
    >
      <div onClick={handleClickChild}>{children}</div>
    </div>
  );
};
