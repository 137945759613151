import React, { FunctionComponent } from "react";
import "./title.scss";

interface TitleProps {
  title?: string;
  width?: string;
  left?: string;
  bottom?: string;
  className?: string;
}

export const Title: FunctionComponent<TitleProps> = ({
  title,
  width,
  left,
  bottom,
  className,
}) => {
  return (
    <h2 className={`rhTitle ${className}`}>
      <span
        className="titleBg"
        style={{ width: `${width}`, left: `${left}`, bottom: `${bottom}` }}
      />
      {title}
    </h2>
  );
};
